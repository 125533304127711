/*
 * @Author: tangshuo
 * @Date: 2021-01-18 20:16:19
 * @LastEditors: tangshuo
 * @LastEditTime: 2022-02-23 15:42:09
 * @Remarks:
 */
import Vue from 'vue'
import Vuex from 'vuex'
import base from "./modules/base";
import hotel from "./modules/hotel";
import caseR from "./modules/case";
import team from "./modules/team";
import batchOperation from "./modules/batch-operation";
import discover from "@/store/modules/discover";
import Axios from 'axios'

Vue.use(Vuex)

const initState = {
  theme: "Min",
  BranchId: sessionStorage.getItem("BranchId"),
  newCos: null,//上传文件鉴权
  userRoles: null,        //权限判断
  authList: [],//用户菜单权限
  headList: [], //导航栏
  homeData: {},//门户页数据
  authButton: {},//用户按钮权限
  userPhone: "",//登录账号
  mesNum: 0, //消息数量
  branchType: 6,//店铺类型
  isShowBrowser: false,//是否显示图片(视频)浏览器
  sourceList: [],//资源列表
  sourceIndex: -1,//当前资源索引
  folderInfo: {
    Remark: '',
    imgData: [],
    zpData: [],
    jgData: [],
    hotelData: [],
  },//资料夹信息
  isKeyDown: true,//案例页是否能使用按键翻页
  showAddFolderDig: false,//是否显示添加资料夹弹窗
  addParam: { coverPath: '' }, //采集到资料集的信息
  zlCount: 0,//资料夹内容数量
  helpMinData: {},
  isLogin: false,//是否登录
  useSpaceInfo: {},//使用空间
  isLock: false,//是否锁定该功能(该功能未解锁)
  pageAuthCode: ["Company", "Team", "Case", "Hotel", 'Banquet_Hall', 'Article',"Inspira","discover"], // 哪些页面有被锁的权限  注意，此处修改了 APP.vue  saveState方法的地方也要改
  navAllList: ['/case','/company','/hotel','/team','/inspira','/banquet_hall', '/article','/teamcase','/discover'],// 除后台之外的导航栏
  isLoginToHome: false, // 是否是登录页登录进来home.vue的
  // 待完成任务
  userTask: {
    data: null,
    taskDisplayed: false, //是否显示过
    unfinished: false, // 是否任务未完成
  },
  caseCoverShowWay: 'cross',  // 案例封面图展示方式 vertical 竖版、 cross 横版
  caseField: [], // 案例 字段信息
  // 金刚案例展示 新版 还是旧版
  UserShowSetInfo: {
    IsShowTip: false, // 是否弹出新版提示框  false不需要 true需要
    ShowRemark: "2", // 1.旧版   2.新版
    UserId: 0,
  },
  warehouse: { // 仓库
    keyName: '',
    label_shopId: 0, // 筛选 标签的店铺ID
    label: [],
    displayMode: -1, // 0 横图 、 1 竖图 2 表格
    fields: {}, // 字段
  },
  // 发现权限
  discoverAuth: [],
  isVisitorCase: 0, // 案例访客模式 : 1 访客模式 ， 0 非访客模式
  shareId: '',
  authAppId: '',
};
export default new Vuex.Store({
  state: sessionStorage.getItem('insercell') ? JSON.parse(sessionStorage.getItem('insercell')) : initState,
  mutations: {
    /**
     * 重置
     * @param state
     */
    resetState(state) {
      Object.assign(state, initState)
    },
    userPhone(state, val) {
      state.userPhone = val
    },
    setMesNum(state, val) {
      state.mesNum = val
    },
    setFolderInfo(state, data) {
      state.folderInfo = {
        imgData: data.imgData || [],
        jgData: data.jgData || [],
        zpData: data.zpData || [],
        hotelData: data.hotelData || [],
        goodsData: data.goodsData || [],
        hotelTypeData: data.hotelTypeData || [],
        Id: data.Id || '',
        Remark: data.Remark || '',
      }
    },
    // 存储仓库信息
    setWarehouse(state, data) {
      state.warehouse = {
        ...(state.warehouse || {}),
        ...data
      }
    },
  },
  actions: {
    /**
     * 获取资料夹数量 页面刷新 或者 初始化的时候 调的
     */
    getFolderCount(store, hostAuthStr) {
      let Authorization = hostAuthStr;
      Axios({
        method: "GET",
        headers: {
          Authorization
        },
        url: process.env.VUE_APP_TEAMURL + "xlapi/HostManage/ShareWork/Folder/FolderCount"
      }).then(res => {
        if (res && res.data && res.data.status) {
          store.state.zlCount = res.data.data;
          // store.commit('setFolderInfo', {});
        }
      })
    },
    /**
     * 获取资料夹信息API
     */
    getFolderApi(store, hostAuthStr) {
      let Authorization = hostAuthStr;
      let cosIp = sessionStorage.getItem("BranchCosCdnUrl_B");
      let imgPath = "https://weddingbo-1256393015.cos.ap-chengdu.myqcloud.com/"; //金刚系统存储桶前缀
      let axiosObj = {
        method: "GET",
        headers: {
          Authorization
        },
        url: process.env.VUE_APP_TEAMURL + "xlapi/HostManage/ShareWork/Folder/FolderGet"
      };
      Axios(axiosObj).then(res => {
        if (res.data.status) {
          //对拿到的数据进行处理
          let imgData = res.data.imgData || [];
          let jgData = res.data.jgData || [];
          let zpData = res.data.zpData || [];
          let hotelData = res.data.hotelData || [];
          let goodsData = res.data.goodsData || [];
          let hotelTypeData = res.data.hotelTypeData || [];

          // 酒店数据
          hotelData.forEach(item => {
            item.Url = '';
            item.coverImgSrc = '';
            item.worksName = '';
            item.HotelAddress = '';
            if (item.Detail) {
              const detail = Object.prototype.toString.call(item.Detail) === '[object Array]' ? (item.Detail.length > 0 ? item.Detail[0] : {}) : item.Detail;
              item.Works = detail.HotelId;

              item.Url = detail.FileThePath;
              if (
                  item.Url &&
                  item.Url.indexOf('https://') === -1 &&
                  item.Url.indexOf('http://') === -1
              ) item.Url = cosIp + item.Url;

              item.coverImgSrc = item.Url;
              item.worksName = detail.HotelName;
              item.CityName = detail.CityName;
              item.HotelAddress = detail.HotelAddress;
            }
          });
          //金刚数据
          jgData.forEach((team) => {
            let coverImgSrc = "";
            let coverImgYsSrc = "";
            if (
              team.FirstBannerImg &&
              (team.FirstBannerImg.indexOf("http://") > -1 ||
                team.FirstBannerImg.indexOf("https://") > -1)
            ) {
              coverImgSrc = team.FirstBannerImg;
            } else if (
              team.FirstBannerImg &&
              team.FirstBannerImg.indexOf("http://") < 0 &&
              team.FirstBannerImg.indexOf("https://") < 0
            ) {
              coverImgSrc = imgPath + team.FirstBannerImg;
            }
            if (
              team.FirstBannerYsImg &&
              (team.FirstBannerYsImg.indexOf("http://") > -1 ||
                team.FirstBannerYsImg.indexOf("https://") > -1)
            ) {
              coverImgYsSrc = team.FirstBannerYsImg;
            } else if (
              team.FirstBannerYsImg &&
              team.FirstBannerYsImg.indexOf("http://") < 0 &&
              team.FirstBannerYsImg.indexOf("https://") < 0
            ) {
              coverImgYsSrc = imgPath + team.FirstBannerYsImg;
            }
            team.coverImgSrc = coverImgYsSrc || coverImgSrc;

            // 报价
            team.OfferStr = '';
            let OfferBbj = {}
            if (team.Offer && team.Offer.length) {
              team.Offer.forEach(c => {
                if (c.Money !== null) OfferBbj[c.Money] = c.Money;
              })

              let moneyList = Object.keys(OfferBbj);
              if (moneyList && moneyList.length > 2) {
                moneyList = moneyList.sort(function(a,b){return a-b;});
                moneyList = [moneyList[0], moneyList[moneyList.length - 1]];
              }
              team.OfferStr = moneyList.join('-');
            }
          });
          //作品数据
          zpData.forEach((works) => {
            let coverImgSrc = "";
            if (works.Type == 1) {
              if (
                works.Detail &&
                works.Detail.FileImageTh &&
                (works.Detail.FileImageTh.indexOf("http://") > -1 ||
                  works.Detail.FileImageTh.indexOf("https://") > -1)
              ) {
                coverImgSrc = works.Detail.FileImageTh;
              } else if (
                works.Detail &&
                works.Detail.FileImageTh &&
                works.Detail.FileImageTh.indexOf("http://") < 0 &&
                works.Detail.FileImageTh.indexOf("https://") < 0
              ) {
                coverImgSrc = cosIp + works.Detail.FileImageTh;
              }
              works.lableList = works.Detail && works.Detail.LabelList
                ? works.Detail.LabelList
                : [];
              works.worksName = works.Detail ? works.Detail.FileName : '';
            } else {
              if (
                works.Detail &&
                works.Detail.CoverImg &&
                (works.Detail.CoverImg.indexOf("http://") > -1 ||
                  works.Detail.CoverImg.indexOf("https://") > -1)
              ) {
                coverImgSrc = works.Detail.CoverImg;
              } else if (
                works.Detail &&
                works.Detail.CoverImg &&
                works.Detail.CoverImg.indexOf("http://") < 0 &&
                works.Detail.CoverImg.indexOf("https://") < 0
              ) {
                coverImgSrc = imgPath + works.Detail.CoverImg;
              }
              works.lableList = works.Detail && works.Detail.VideoLabel
                ? works.Detail.VideoLabel.split(",")
                : [];
              works.worksName = works.Detail ? works.Detail.OpusName : '';
            }
            works.coverImgSrc = coverImgSrc;
          });
          //图集数据
          imgData.forEach((img) => {
            if (img.Detail && img.Source == 1) {
              //案例图片
              img.worksName = img.Detail.FileName;
            } else if (img.Detail && img.Source == 2) {
              //金刚图片
              img.worksName = img.Detail.OpusName;
            } else {
              //灵感图片
            }
          });

          // 物品
          goodsData.forEach(c => {
            c.Detail = c.Detail && c.Detail[0] ? c.Detail[0] : {};
            if (
                c.Detail && c.Detail.GoodsId
            ) {
              let coverImgSrc = c.Detail.Thumbnail;
              if (
                  c.Detail.Thumbnail &&
                  c.Detail.Thumbnail.indexOf("http://") < 0 &&
                  c.Detail.Thumbnail.indexOf("https://") < 0
              ) {
                coverImgSrc = cosIp + c.Detail.Thumbnail;
              }
              c.worksName = c.Detail ? c.Detail.GoodsName : '';
              c.coverImgSrc = coverImgSrc;

              c.LabelList = c.Detail.LabelList;
              c.Price = c.Detail.UsePrice;
            }
          })

          // 场地
          hotelTypeData.forEach(c => {
            c.Detail = c.Detail && c.Detail[0] ? c.Detail[0] : {};
            if (
                c.Detail && c.Detail.Id
            ) {
              let coverImgSrc = c.Detail.ThePath;
              if (
                  c.Detail.ThePath &&
                  c.Detail.ThePath.indexOf("http://") < 0 &&
                  c.Detail.ThePath.indexOf("https://") < 0
              ) {
                coverImgSrc = cosIp + c.Detail.ThePath;
              }
              c.worksName = c.Detail ? c.Detail.Title : '';
              c.coverImgSrc = coverImgSrc;

              // c.ShowFiledVal = [];
              // if (c.Detail.ShowFileds) {
              //     try {
              //         c.ShowFiledVal = JSON.parse(c.Detail.ShowFileds)
              //     } catch (err) {
              //         c.ShowFiledVal = [];
              //     }
              // }
              c.Price = c.Detail.Price;
            }
          })

          store.commit('setFolderInfo', {
            imgData: imgData,
            jgData: jgData,
            zpData: zpData,
            hotelData: hotelData,
            goodsData: goodsData,
            hotelTypeData: hotelTypeData,
            Id: res.data.Id,
            Remark: res.data.Remark,
          })

          store.state.zlCount =
            res.data.imgData.length +
            res.data.jgData.length +
            res.data.zpData.length +
            res.data.hotelData.length +
            res.data.goodsData.length +
            res.data.hotelTypeData.length
        } else {
          console.log(res.data.msg ? res.data.msg : "获取资料夹信息失败");
          // this.$Error(res.data.msg ? res.data.msg : "获取资料夹信息失败");
        }
      }).catch(err => {
        console.log(err);
        console.log('获取资料夹信息失败');
        // this.$Error("获取资料夹信息失败");
      });
    },
  },
  modules: {
    base, caseR, hotel,team,batchOperation, discover
  }
})
